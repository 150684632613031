<template>
  <div class="pbf">
     
     <BrandTabHarvests
      :inurl="'/harvests'"      
      :product-id="props.id"
      />

  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
}

const props = defineProps<Props>()

</script>